@font-face {
  font-family: "Atlas Grotesk";
  src: url("../fonts/AtlasGrotesk-Light.otf");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Atlas Grotesk";
  src: url("../fonts/AtlasGrotesk-Regular.otf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Atlas Grotesk";
  src: url("../fonts/AtlasGrotesk-Medium.otf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Atlas Grotesk";
  src: url("../fonts/AtlasGrotesk-Bold.otf");
  font-weight: 600;
  font-style: normal;
}

body {
  background-color: #0f0b29;
  font-family: "Atlas Grotesk";
  font-weight: 300;
  overflow: hidden;
  &.disable-scroll {
    height: 100%;
    overflow-y: hidden;
  }
  // overflow: auto;
}

.scrollbar-track.scrollbar-track-y {
  background-color: transparent;
}
.scrollbar-thumb.scrollbar-thumb-y {
  background-color: black;
}
a:before,
a:after,
a > span:before,
a > span:after {
  content: "";
  position: absolute;
  transition: transform 0.5s ease;
}
.hover-1 {
  padding-top: 10px;
  &:hover,
  &active {
    color: #d3b4ff !important;
  }
}
.hover-1:before {
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: #d3b4ff;
  transform: scaleX(0);
}
.hover-1:hover:before,
.hover-1.active:before {
  transform: scaleX(1);
}

.navbar {
  font-size: 16px;

  .navbar-nav {
    padding: 15px;
    padding-top: 8px;
    padding-bottom: 8px;

    li {
      padding: 0 10px !important;
    }
  }

  &.navbar-opacity {
    background: transparent !important;
    .navbar-nav {
      background-color: #111c4485;
      // background-color: #ffffff17;
      border-radius: 27px;
      // box-shadow: rgb(112 144 176 / 6%) 14px 17px 40px 4px;
      backdrop-filter: blur(3px);
      transition: 0.5s ease;
    }
  }
}

@media screen and (max-width: 991px) {
  .navbar {
    .navbar-collapse {
      width: calc(100%);
      margin: 0;
      border-radius: 0;
      height: 100vh !important;
      background-color: #1e0245 !important;
    }

    button.navbar-toggler.navbar-toggler {
      margin-left: auto;
    }

    button.navbar-toggler.close {
      opacity: 1;
      font-size: 30px !important;
      &:hover {
        opacity: 1;
        color: red !important;
      }
    }

    .navbar-nav {
      background: transparent !important;
      backdrop-filter: none !important;
      box-shadow: none !important;
      margin-top: 40px;
      .nav-item {
        font-size: 32px;
      }
    }
  }
}

.stack-layer {
  width: 375px;
  height: 375px;

  @media screen and (max-width: 991px) {
    width: 275px;
    height: 275px;
    .stack-layer-1 {
      top: -160px !important;
    }
    .stack-layer-2 {
      position: relative;
      top: -320px !important;
    }
  }

  margin: auto;
  position: relative;
  .stack-layer-0 {
    position: relative;
    z-index: 500;
  }
  .stack-layer-1 {
    position: relative;
    top: -200px;
    z-index: 400;
  }
  .stack-layer-2 {
    position: relative;
    top: -400px;
    z-index: 300;
  }
  .stack-circle {
    position: relative;
    width: 500px;
    top: -1300px;
    margin: auto;
    z-index: 200;
  }
}

.logo-box {
  display: inline-flex;
  height: 55px;
  width: 200px;
  margin: auto;

  svg {
    width: 100%;
  }
  // padding: 10px;
  // margin: 10px;
  // vertical-align: middle;
  // border: solid 2px white;
  // border-radius: 0.8em;

  // .h1-seo {
  //   font-weight: 400 !important;
  //   color: white;
  //   padding-left: 8px;
  //   padding-right: 7px;
  //   font-size: 3.5vh;
  // }
}

.brand {
  div.title-desp {
    margin-top: 30px;
    font-size: 25pt;
    font-weight: 400 !important;
    @media screen and (max-width: 991px) {
      font-size: 19pt;
    }

    .t-word-wrapper {
      overflow: hidden;
      display: inline-flex;
      .t-word {
        display: inline-block;
        overflow: hidden;
      }
    }
  }
}
.feature-wrapper {
  overflow: hidden;
  h1,
  h2,
  h3,
  h4 {
    margin-bottom: 0;
    color: white;
  }
  margin-bottom: 20px;
}
.page-header.header-filter {
  // height: 650vh !important;
  // @media screen and (max-width: 991px) {
  //   height: 7900px !important;
  // }
  // max-height: unset !important;
  .sections {
    position: absolute;
    top: 130vh;
    width: 100%;
    z-index: 3;
  }
  .stack-layer {
    top: 150px;
  }
  .content-center.brand {
    top: 680px;
    @media screen and (max-width: 991px) {
      top: 450px;
    }
  }
  .bg-1 {
    position: absolute;
    top: 800px;
    left: 35%;
    width: 200vw;
    max-width: unset;
  }
  .bg-3 {
    position: absolute;
    top: 4600px;
    left: 35%;
    width: 200vw;
    max-width: unset;
  }
  .bg-2 {
    position: absolute;
    top: 2300px;
    right: 35%;
    width: 200vw;
    max-width: unset;
  }
  .product {
    .product-title-holder {
      background-color: #1e0245a8;
      padding-left: 30px;
    }
    width: 100%;
    margin-top: 30px;

    .product-title {
      position: absolute;
      top: 110px;
      left: 60px;
      &.text-right {
        left: auto;
        right: 60px;
      }
      margin-bottom: 10px !important;
      font-weight: 500;
      overflow: hidden;
      color: white !important;
      span {
        position: relative;
      }
    }

    &.product-2-title {
      margin-top: 250px;
      @media screen and (max-width: 991px) {
        margin-top: 30px;
      }
    }
    .product-short-desp {
      margin-top: -35px;
      font-weight: 300;
      color: rgb(255, 255, 255);
    }
    .product-description {
      margin-top: -20px;
      max-width: 800px;
    }
    p {
      font-size: 0.9rem;
      line-height: 26px;
    }

    .tim-icons {
      margin-right: 10px;
    }

    .phone {
      position: relative;
      overflow: visible;
    }

    .feature {
      padding: 10px;
      padding-left: 30px;
      margin-bottom: 20px;
      @media screen and (max-width: 991px) {
        // font-size: 19pt;
        margin-bottom: 20px;
      }

      hr {
        width: 40%;
      }
    }

    .features-center {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &.product-ready {
      // background-color: #430895;
      // background-image: url(../img/bg-3.jpg);
      // background-repeat: no-repeat;
      // background-size: cover;
      background-color: #1e0245;
      height: 600px;
      z-index: 1024;
      margin-top: 400px;
      @media screen and (max-width: 991px) {
        margin-top: 200px;
      }
    }
    hr {
      width: 20%;
      @media screen and (max-width: 991px) {
        width: 80%;
      }
    }
    .ready-left {
      // background-color: #e62b33;
      padding: 50px;
      background-color: rgb(171 2 87);
      color: #fff;
      transition: background-color 0.3s cubic-bezier(0.4, 0, 0, 1);
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      max-height: 450px;
      cursor: pointer;

      &:hover {
        background-color: rgb(225, 13, 119);
      }

      .talk {
        position: absolute;
        bottom: 10px;
        right: 20px;
        font-size: 12pt;
        color: #fff;
      }
    }
    .ready-right {
      // padding: 50px;
      // padding-left: 150px;
      // h1 {
      //   color: rgb(0, 0, 0) !important;
      // }
      height: 0;
    }
  }
}

.viewport {
  width: 100vw;
  height: 100vh;
  overflow: hidden !important;
  &-touch {
    overflow: auto !important;
  }
}

.viewport-container {
  overflow: hidden !important;
  &.touch {
    width: 100vw;
    height: 100vh;
    overflow-y: scroll !important;
  }
}

.page-security,
.page-about,
.page-contact {
  // height: 4000px;
  margin-bottom: 10vh;
  .section-1 {
    background-image: url(../img/bg-5.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 200px;
    @media screen and (max-width: 991px) {
      padding-top: 100px;
    }
    box-shadow: 0px 4px 4px 0px #00000040, inset 0 0 0 1000px rgba(29, 2, 96, 0.46);
    min-height: 100vh;
    transform-origin: bottom left;

    .feature-2 .colored {
      padding: 40px;
    }
  }

  .section-2 {
    // min-height: 75vh;
    background-color: #0f0b29;

    .feature-3 .colored {
      padding-top: 50px;
    }
    .bc-holder {
      z-index: 3;
      background-color: #0f0b29;
      .bc-container {
        width: 100%;
        svg {
          width: 100%;
        }
      }
    }
  }
  .section-3 {
    min-height: 65vh;
    @media screen and (max-width: 991px) {
      min-height: 20vh;
    }
    background-image: url(../img/bg-5.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: 0px 4px 4px 0px #00000040, inset 0 0 0 1000px rgba(29, 2, 96, 0.46);
  }
  .section-4 {
    background-color: #400a8b;
    .colored,
    .colored-red {
      padding: 40px;
      padding-top: 50px;
      padding-bottom: 60px;
    }
    .colored-red {
      z-index: 3;
    }
  }
  .section-5 {
    min-height: 100vh;
    background-image: url(../img/bg-8.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: 0px 4px 4px 0px #00000040, inset 0 0 0 1000px rgba(29, 2, 96, 0.46);
    .feature-5 {
      padding-top: 20vh;
      @media screen and (max-width: 991px) {
        padding-top: 0vh;
      }
      .data-flow-chart {
        overflow-x: auto;
        svg {
          min-width: 1000px;
        }
      }
    }
    .feature-6 {
      padding-top: 40vh;
      padding-bottom: 20vh;
      .feature-wrapper {
        margin: auto;
      }
    }
  }

  .colored {
    padding: 30px;
    background-color: #400a8b;
    // background-color: rgba(171, 2, 87, 0.77);
    // background-color: #ffffff17;
    // box-shadow: rgb(112 144 176 / 6%) 14px 17px 40px 4px;
    &.blur {
      backdrop-filter: blur(3px);
      background-color: #400a8bca;
    }
    transition: 0.5s ease;
    &-red {
      background-color: rgb(171 2 87) !important;
      &.blur {
        background-color: rgba(171, 2, 87, 0.682) !important;
      }
    }
  }
  .container {
    width: 100%;
    max-width: 100%;
  }
  h1 {
    font-size: 37pt;
    color: white;
    font-weight: 500;
  }
  h2 {
    color: white;
    @media screen and (max-width: 991px) {
      font-size: 18pt;
      font-weight: 500;
    }
    .tim-icons {
      font-size: 20pt;
      padding-bottom: 3px;
    }
  }
  h3 {
    color: white;
    .tim-icons {
      font-size: 17pt;
    }
  }

  .tim-icons {
    font-size: 15pt;
    margin-right: 10px;
  }

  p {
    font-size: 13pt;
    color: rgb(255, 255, 255);
  }

  .security-feature {
    color: black;
    // margin-bottom: 200px;
    .col {
    }
  }
}

.page-about {
  margin-bottom: 10vh;
  .container {
    width: 100%;
    max-width: 100%;
  }
  .section-1 {
    background-image: url(../img/bg-12.jpg);
    background-repeat: no-repeat;
    background-size: cover;

    box-shadow: 0px 4px 4px 0px #00000040, inset 0 0 0 1000px rgba(35, 0, 125, 0.48);
    transform-origin: top right;

    .feature-2 .colored {
      padding: 40px;
    }

    .feature-2 {
      margin-top: 30vh;
      @media screen and (max-width: 991px) {
        margin-top: 10vh;
      }

      h2 {
        font-size: 26pt;
        color: white;
        font-weight: 500;
      }

      .profiles {
        display: flex;
        align-content: center;
        margin-bottom: 40vh;
      }

      // .profiles {
      //   max-width: 70em;
      //   margin: 0 auto;
      //   padding: 0 2em;
      //   justify-content: space-between;
      // }
      a {
        // transition: 0.3s all ease-in;
        position: relative;
        z-index: 1;
        // filter: grayscale(80%);
        opacity: 1;
        max-width: 250px;

        h3 {
          margin-bottom: 5px;
        }
      }

      a.animated {
        transition: 0.5s all ease-out !important;

        &:hover {
          transform: scale(1.2) !important;
          z-index: 2;
          filter: grayscale(0);

          opacity: 1;
        }
      }

      a div {
        position: absolute;
        width: 100%;
        height: 0;
        bottom: 0;
        background-color: #400a8bca;
        background-color: rgba(0, 0, 0, 0.4);
        padding: 8px;
        font: 400 12px/1 arial;
        opacity: 0;
        transition: 0.5s all ease-out;
        color: #fff;
        overflow: hidden;
        border-top: 1px solid rgba(0, 0, 0, 0);
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      h3 {
        font-size: 14px;
        line-height: 1.3;
        color: white;
        font-weight: 400;
      }

      h4 {
        color: white;
        font-size: 12px;
        margin-bottom: 0;
      }

      span {
        color: rgb(255, 255, 255);
      }

      a:hover div {
        opacity: 1;
        height: 70px;
        bottom: 0px;
        border-top: 1px solid rgba(0, 0, 0, 0.2);
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }

      img {
        max-width: 100%;
        transition: 0.3s all ease-in;
      }

      a:first-child img {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
      a:last-child img {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }

      a:hover img {
        border-radius: 4px;
        box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.06);
      }

      @media (max-width: 767px) {
        .profiles {
          justify-content: space-around;
          flex-wrap: wrap;
        }
        .profiles a {
          width: 50%;
        }
      }
    }
  }
}

.page-contact {
  min-height: 100vh;
  max-height: 999px;
  padding: 0;
  color: #fff;
  position: relative;
  overflow: hidden;
  .squares {
    background: #4400fff8;
    background: linear-gradient(150deg, #814de7, #6d0ff1);
  }
  .content-center.brand {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #fff;
    width: 100%;
    max-width: 880px;

    padding: 80px;
    border-radius: 1rem;
    @media screen and (max-width: 991px) {
      padding-left: 20px;
      padding-right: 20px;
    }

    backdrop-filter: blur(3px);
    // background-color: #400a8bca;
    background-color: rgba(200, 1, 100, 0.76) !important;

    p {
      font-size: 14pt;
      margin-bottom: 50px;
    }

    a {
      padding: 20px;
      font-size: 16pt;
      background-color: rgb(58, 1, 162);
      color: white;
      border-radius: 0.3rem;
      border: none;

      &:hover {
        background-color: rgb(70, 0, 201);
        transition: all 0.5s ease-in-out;
      }
    }
  }
}
